<template>
    <div class="d-flex justify-content-center login-brand">
        <img 
            style="max-width: 50%;"
            alt="Enston Logo" 
            class="img-fluid" 
            :src="`/logo/${dataFileName}`" 
        />
        <h2 class="brand-text text-primary ml-1 secretary-text">SECRETARY</h2>
    </div>
</template>

<script>
export default {
    props: {
        dataFileName: {
            type: String,
            required: false,
            default: 'enston.png'
        }
    }
};
</script>

<style scoped>
.login-brand
{
    height: 30px;
    font-size: 24px;
    text-transform: uppercase;
    color: #666;
    text-align: center;
}

.secretary-text
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    letter-spacing: 2px;
    font-size: 2rem;
    padding: 0;
    margin: 0;
}
</style>
